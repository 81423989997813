<template>
	<div >
		<el-form :model="formData" :rules="rules" ref="ruleForm" status-icon label-width="100px"  v-loading="loading">
			<el-form-item label="代理名称" prop="name">
				<el-input type="text" placeholder="请输入代理名称" v-model="formData.name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="负责人" prop="full_name">
				<el-input type="text" placeholder="请输入负责人" v-model="formData.full_name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="代理头像" prop="avatar">
				<el-upload class="avatar-uploader" :action="url+'api/imgFile'" :show-file-list="false" :on-success="logoSuccess" :before-upload="logoUpload"><img v-if="formData.avatar" :src="url+formData.avatar" class="avatar"><i v-else class="el-icon-plus avatar-uploader-icon"></i></el-upload>
			</el-form-item>
			<el-form-item label="联系电话" prop="phone">
				<el-input type="text" placeholder="请输入联系电话" v-model="formData.phone" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="费率/%" prop="rate">
				<el-input @input="rate" class="no-number" type="number" placeholder="请输入费率" v-model="formData.rate" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="登录账户" prop="username">
				<el-input type="text" placeholder="请输入登录账户" v-model="formData.username" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>
		<div class="drawer-footer">
			<Button style="margin-right: 8px">重置</Button>
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
import { handleError } from "@vue/runtime-core";
export default {
	
	data () {
		return {
			formData:{
				name:"",
				avatar:"",
				phone:"",
				username:"",
                id:"",
				full_name:"",
				rate:0
			},
            url:this.common.url,
			loading:false,
			rules:{
				name:[
					{required: true, message: '请输入代理名称', trigger: 'blur' },
					{max:40, message: '长度在40个字符', trigger: 'blur' }
				],
				full_name:[
					{required: true, message: '请输入负责人', trigger: 'blur' },
					{max:30, message: '长度在30个字符', trigger: 'blur' }
				],	
				avatar:[
					{required: true, message: '请上传头像', trigger: 'blur'}
				],
				rate:[
					{required: true, message: '请输入费率', trigger: 'blur'},
					{type:"number",min:0,max: 100, message: '费率在0到100之间', trigger: 'blur'}
				],
				phone:[
					{required: true, message: '请输入联系电话', trigger: 'blur'},
					{ type: 'number',
						message: '手机号格式不正确',
						trigger: 'blur',
						transform (value) {
							var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
							if ( !phonereg.test(value)) {
								return false
							}else{
								return Number(value)
							}
							
						}
					}
				],
				username:[
					{required: true, message: '请输入登录账户', trigger: 'blur'},
					// {min:5, message: '长度在5个字符', trigger: 'blur' },
					{max:50, message: '长度在50个字符', trigger: 'blur' }
				],
			}
		}
	},
	methods:{
		
		formDataPid(value) {
			if(value.length>0){
				this.formData.pid=value[0]
			}else{
				this.formData.pid=0
			}
		},
		onSubmit(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
			var that = this
			this.loading = true
			return request({
				url:'promoter/edit/'+this.formData.id,
				method:"post",
				data:this.formData
			}).then(response  => {
				this.loading = false
				if(response.code==200){
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.$emit('routerCancel',true)
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
		logoSuccess(res, file) {
			// this.logo =  URL.createObjectURL(file.raw);
			this.formData.avatar =res.data.image;
		},
		logoUpload(file){
			const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error('上传头像logo只能是 JPG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像logo大小不能超过 2MB!');
			}
			return isJPG && isLt2M;
		},
        detail(id){
            this.loading = true
            return request({
                    url:'promoter/edit/'+id,
                    method:"get",
            }).then(response  => {
                this.loading = false
                if(response.code!=200){
                    this.$Message.error({
                        content:response.msg,
                        duration: 3
                    })
                    return
                }else{
                    this.formData = response.data
                }
            }).then(err => {
                if(err){
                        this.loading = false
                    this.$Message.error("系统异常，请稍后重试")
                }
            })
        },
		rate(val){
			this.formData.rate = val-0
		}
	},
    watch:{
        '$route.params': {
                handler(newVal,oldVal){
                    this.formData.id = newVal.id
                    this.detail(newVal.id)
                },
            immediate: true
        }
    }

}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.no-number::-webkit-outer-spin-button,
	.no-number::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none !important;
	}
	
	.no-number input[type="number"]::-webkit-outer-spin-button,
	.no-number input[type="number"]::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none !important;
	}
	
	/*在firefox下移除input[number]的上下箭头*/
	.no-number {
	-moz-appearance: textfield;
	}
	
	.no-number input[type="number"] {
	-moz-appearance: textfield;
	}
</style>